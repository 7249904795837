@import url('https://fonts.googleapis.com/css?family=monospace&display=swap');

/* .dashboard {
  display: flex;
  flex-direction: column;
  width: 95%;
  background: teal;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#35394a', endColorstr='#1f222e',GradientType=1 ); 
  border-radius: 16px;
  opacity: 0.9;
  box-shadow: 0.5px 2px 7px #282c34;
} */

.dashboard{
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  width: 95%;
  background: teal;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#35394a', endColorstr='#1f222e',GradientType=1 ); 
  position: fixed;
  align-self: center;
  height: 80%;
  border-radius: 16px;
  opacity: 0.9;
  box-shadow: 0.5px 2px 7px #282c34;
  padding-top: 50px;
  padding-left: 20px;
  margin-bottom: 2px;
}

.dashboard form {
  display: flex;
  flex-direction: row;
  float: left;
  margin-top: 0;
  width: 60%;
  align-self: center;
  border-radius: 9px;
  margin-bottom: 0;
}

.dashboard form > .textbox {
  outline: 0;
  height: 52px;
  width: 70%;
  line-height: 42px;
  padding: 0 16px;
  margin: 0%;
  background-color: rgba(255, 255, 255, 0.8);
  color: #212121;
  border: 0;
  float: left;
  font-size: 14pt;
  font-weight: 900;
  border-radius: 8px 0 0 8px;
  font-family:
    "SFMono-Regular",
    Consolas,
    "Liberation Mono",
    Menlo,
    Courier,
    monospace;
}

.dashboard form > .textbox:focus {
  outline: 0;
  background-color: #FFF;
}

.dashboard form > .button {
  outline: 0;
  margin-top: 1%;
  margin-left: 0;
  background: none;
  background-color: rgba(38, 50, 56, 0.8);
  float: left;
  height: 52px;
  width: 30%;
  text-align: center;
  line-height: 42px;
  border: 0;
  color: #FFF;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 16px;
  text-rendering: auto;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  -webkit-transition: background-color .4s ease;
  transition: background-color .4s ease;
  -webkit-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
}

.dashboard form > .button:hover {
  background-color: rgba(0, 150, 136, 0.8);
}

.dashboard .popular {
  border-top: 1px solid #282c34;
  width: 96%;
  align-self: center;
  display: flex;
  flex-direction: column;
  flex-flow: wrap;
}

.dashboard h3 {
  width: 100%;
  align-self: center;
  font-size: 14pt;
  font-family:
    "SFMono-Regular",
    Consolas,
    "Liberation Mono",
    Menlo,
    Courier,
    monospace;
}

.dashboard h4 {
  width: 100%;
  align-self: center;
  font-size: 15pt;
  padding: 0;
  margin-top: 4px;
  font-family:
    "SFMono-Regular",
    Consolas,
    "Liberation Mono",
    Menlo,
    Courier,
    monospace;
}

.dashboard .book-cards {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  height: 270px;
}

.dashboard .add-button {
  outline: 0;
  background: none;
  background-color: rgba(38, 50, 56, 0.8);
  float: left;
  height: 52px;
  width: 100%;
  text-align: center;
  border: 0;
  margin: 4px;
  margin-bottom: 10px;
  color: #FFF;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 16px;
  text-rendering: auto;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  -webkit-transition: background-color .4s ease;
  transition: background-color .4s ease;
  -webkit-border-radius: 0 4px 4px 0;
  border-radius: 6px;
  cursor: pointer;
  font-family:
    "SFMono-Regular",
    Consolas,
    "Liberation Mono",
    Menlo,
    Courier,
    monospace;
}

.dashboard .book {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
}
@media screen and (max-width: 800px) {
  .dashboard {
    margin-top: 60%;
    height: 60%;
  }
  .dashboard form {
    width: 90%;
  }
  .dashboard .book-cards {
    flex-direction: row;
    width: 100%;
  }
}
