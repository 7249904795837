@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  padding: 0;
  box-shadow: 0.5px 2px 7px #282c34;
  align-self: center;
  height: 40px;
  background-color: white;
  opacity: 0.8;
  background-blend-mode: exclusion;
}

.header .logo {
  display: flex;
  flex-direction: row;
  width: 13%;
  min-width: 160px;
  margin-left: 100px;
  padding: 0.3%;
}

.header .nav-link {
  display: flex;
  flex-direction: row;
  width: 60%;
  justify-content: space-evenly;
  align-self: center;
}

.header .link {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  max-width:140px;
  text-align: center;
  font-size: 14pt;
  height: 40px;
  opacity: 1;
  min-width:fit-content;
  width:25%;
}

.header .link div{
  align-self: center;
}

.header .link-icon {
  width: 30px;
  height: 30px;
  color:black;
}

.header a {
  text-decoration: none;
  font-family: 'Roboto';
  font-weight: 900;
  color: teal;
  padding-top: 1.2%;
  margin-top: 0;
}

.header .link:hover {
  border-bottom: 1px solid teal;
  background-color: teal;
  border-color: teal;
  color: white;
  height: 40px;
}

.header .link:focus {
  background-color: teal;
  border-bottom: 1px solid teal;
  background-color: teal;
  border-color: teal;
  color: white;
  height: 40px;
}

@media screen and (max-width: 800px) {
  .header {
    height: fit-content;
    padding:2%;
    flex-direction: column;
  }
  .header .logo {
    width: 40%;
    min-width: 160px;
    margin:0;
    align-self: center;
  }
  .header .nav-link{
    min-width: 120px;
    flex-direction: column;
    width:100%;
    max-width: none;
    padding:2%;
  }
  .header .link{
    max-width: 250px;
    width:70%;
    display: flex;
    justify-content: space-between;
    margin:2% auto;
  }

  .header .link:nth-child(odd){
    width:100%;
    background-color: teal;
    opacity:.7;
    color:white;
  }
}

.header button{
  height:fit-content;
  padding:5px 10px;
  align-self: center;
  border:none;
  border-radius: 4px;
  background-color: teal;
  color:white;
  font-weight: bold;
  font-size: 16px;
}