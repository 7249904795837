.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  align-self: flex-end;
  width: 100%;
  background-color: rgb(92, 90, 90);
  padding: 1%;
  box-shadow: 0.5px 2px 7px #282c34;
}

.footer .content {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  justify-items: space-evenly;
  width: 100%;
}

.footer .footer-logo {
  width: 40px;
  align-self: center;
}

@media screen and (max-width: 800px) {
  .footer-logo {
    width: 30px;
  }
}