@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css?family=monospace&display=swap);
@import url(https://fonts.googleapis.com/css?family=monospace&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* * {
  box-sizing: border-box;
} */

html {
  font-size: 62.5%;
}

html, body {
  font-family: 'Roboto', sans-serif;
}

h1, h2, h3, h4, h5 {
  font-family: 'Indie Flower', cursive;
}

h1 {
  font-size: 4rem;
}

h2 {
  font-size: 3.2rem;
  padding-bottom: 10px;
}

h4 {
  font-size: 2.5rem;
  padding-bottom: 10px;
}

p {
  line-height: 1.5;
  font-size: 1.6rem;
  padding-bottom: 10px;
}

img {
  max-width: 100%;
  height: auto;
}

.container {
  width: 100%;
  margin: auto;
}

.App {
  display: flex;
  flex-direction: column;

}

/* Material UI Updates */

.MuiTypography-body2{
  font-size: 1.3rem !important;
}

.MuiTypography-body1{
  font-size: 1.4rem !important;
}

.MuiTypography-h5{
  font-size: 1.7rem !important;
}

.MuiIconButton-root .MuiSvgIcon-root{
  font-size: 2.4rem !important;
  color: teal !important;
}

.MuiAvatar-colorDefault{
  background-color: teal !important;
}

*{
  box-sizing: border-box;
}
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  padding: 0;
  box-shadow: 0.5px 2px 7px #282c34;
  align-self: center;
  height: 40px;
  background-color: white;
  opacity: 0.8;
  background-blend-mode: exclusion;
}

.header .logo {
  display: flex;
  flex-direction: row;
  width: 13%;
  min-width: 160px;
  margin-left: 100px;
  padding: 0.3%;
}

.header .nav-link {
  display: flex;
  flex-direction: row;
  width: 60%;
  justify-content: space-evenly;
  align-self: center;
}

.header .link {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  max-width:140px;
  text-align: center;
  font-size: 14pt;
  height: 40px;
  opacity: 1;
  min-width:-webkit-fit-content;
  min-width:-moz-fit-content;
  min-width:fit-content;
  width:25%;
}

.header .link div{
  align-self: center;
}

.header .link-icon {
  width: 30px;
  height: 30px;
  color:black;
}

.header a {
  text-decoration: none;
  font-family: 'Roboto';
  font-weight: 900;
  color: teal;
  padding-top: 1.2%;
  margin-top: 0;
}

.header .link:hover {
  border-bottom: 1px solid teal;
  background-color: teal;
  border-color: teal;
  color: white;
  height: 40px;
}

.header .link:focus {
  background-color: teal;
  border-bottom: 1px solid teal;
  background-color: teal;
  border-color: teal;
  color: white;
  height: 40px;
}

@media screen and (max-width: 800px) {
  .header {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding:2%;
    flex-direction: column;
  }
  .header .logo {
    width: 40%;
    min-width: 160px;
    margin:0;
    align-self: center;
  }
  .header .nav-link{
    min-width: 120px;
    flex-direction: column;
    width:100%;
    max-width: none;
    padding:2%;
  }
  .header .link{
    max-width: 250px;
    width:70%;
    display: flex;
    justify-content: space-between;
    margin:2% auto;
  }

  .header .link:nth-child(odd){
    width:100%;
    background-color: teal;
    opacity:.7;
    color:white;
  }
}

.header button{
  height:-webkit-fit-content;
  height:-moz-fit-content;
  height:fit-content;
  padding:5px 10px;
  align-self: center;
  border:none;
  border-radius: 4px;
  background-color: teal;
  color:white;
  font-weight: bold;
  font-size: 16px;
}
.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  align-self: flex-end;
  width: 100%;
  background-color: rgb(92, 90, 90);
  padding: 1%;
  box-shadow: 0.5px 2px 7px #282c34;
}

.footer .content {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  justify-items: space-evenly;
  width: 100%;
}

.footer .footer-logo {
  width: 40px;
  align-self: center;
}

@media screen and (max-width: 800px) {
  .footer-logo {
    width: 30px;
  }
}
/* .landing-page{
        display: flex;
        flex-direction: column;
        width: 95%;
        background: teal;
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#35394a', endColorstr='#1f222e',GradientType=1 ); 
        position: fixed;
        align-self: center;
        border-radius: 16px;
        opacity: 0.9;
        box-shadow: 0.5px 2px 7px #282c34;
        transform: translateY(15%);
        margin-top: 60px;
      }  */
.landing-page {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  width: 95%;
  background: teal;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#35394a', endColorstr='#1f222e',GradientType=1 ); 
  position: fixed;
  align-self: center;
  height: 80%;
  border-radius: 16px;
  opacity: 0.9;
  box-shadow: 0.5px 2px 7px #282c34;
  padding-top: 1px;
  padding-left: 20px;
  margin-bottom: 2px;
}
.landing-page form > input {
    outline: 0;
    height: 50px;
    width: 70%;
    margin: 1%;
    line-height: 42px;
    padding: 0 16px;
    background-color: rgba(255, 255, 255, 0.8);
    color: #212121;
    border: 0;
    float: left;
    font-size: 14pt;
    font-weight: 900;
    border-radius: 8px;
    font-family:
      "SFMono-Regular",
      Consolas,
      "Liberation Mono",
      Menlo,
      Courier,
      monospace;
  }
  
.landing-page form > input:focus {
    outline: 0;
    background-color: #FFF;
  }
  
.landing-page form > button {
    outline: 0;
    background: none;
    background-color: rgba(38, 50, 56, 0.8);
    float: left;
    height: 52px;
    width: 30%;
    text-align: center;
    line-height: 42px;
    border: 0;
    color: #FFF;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 16px;
    text-rendering: auto;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    -webkit-transition: background-color .4s ease;
    transition: background-color .4s ease;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
  }
  
.landing-page form > button:hover {
    background-color: rgba(0, 150, 136, 0.8);
  }
  
  
  .landing-page h1 {
    width: 100%;
    align-self: center;
    font-size: 14pt;
    font-family:
      "SFMono-Regular",
      Consolas,
      "Liberation Mono",
      Menlo,
      Courier,
      monospace;
  }
  
  .landing-page h2 {
    width: 100%;
    align-self: center;
    font-size: 15pt;
    padding: 0;
    margin-top: 4px;
    font-family:
      "SFMono-Regular",
      Consolas,
      "Liberation Mono",
      Menlo,
      Courier,
      monospace;
  }
*{
    box-sizing: border-box;
}

.display-saved {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    width: 95%;
    background: teal;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#35394a', endColorstr='#1f222e',GradientType=1 ); 
    position: fixed;
    align-self: center;
    height: 80%;
    border-radius: 16px;
    opacity: 0.9;
    box-shadow: 0.5px 2px 7px #282c34;
    padding-top: 1px;
    padding-left: 20px;
    margin-bottom: 2px;
    overflow: scroll;
  }

  #root > div > div.display-saved > div{
      margin:20px 0;
      overflow: scroll;
      align-self: center;
      justify-self: center;
  }

  #root > div > div.display-saved > div > div.MuiCardHeader-root.makeStyles-header-222 > div.MuiCardHeader-content > span{
      font-size:3rem !important;
  }

  .desc{
      width:80%;
      margin-right:0 !important;
      justify-self: center;
  }

  .desc #root > div > div.display-saved > div{
      margin: 0;
  }
/* .dashboard {
  display: flex;
  flex-direction: column;
  width: 95%;
  background: teal;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#35394a', endColorstr='#1f222e',GradientType=1 ); 
  border-radius: 16px;
  opacity: 0.9;
  box-shadow: 0.5px 2px 7px #282c34;
} */

.dashboard{
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  width: 95%;
  background: teal;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#35394a', endColorstr='#1f222e',GradientType=1 ); 
  position: fixed;
  align-self: center;
  height: 80%;
  border-radius: 16px;
  opacity: 0.9;
  box-shadow: 0.5px 2px 7px #282c34;
  padding-top: 50px;
  padding-left: 20px;
  margin-bottom: 2px;
}

.dashboard form {
  display: flex;
  flex-direction: row;
  float: left;
  margin-top: 0;
  width: 60%;
  align-self: center;
  border-radius: 9px;
  margin-bottom: 0;
}

.dashboard form > .textbox {
  outline: 0;
  height: 52px;
  width: 70%;
  line-height: 42px;
  padding: 0 16px;
  margin: 0%;
  background-color: rgba(255, 255, 255, 0.8);
  color: #212121;
  border: 0;
  float: left;
  font-size: 14pt;
  font-weight: 900;
  border-radius: 8px 0 0 8px;
  font-family:
    "SFMono-Regular",
    Consolas,
    "Liberation Mono",
    Menlo,
    Courier,
    monospace;
}

.dashboard form > .textbox:focus {
  outline: 0;
  background-color: #FFF;
}

.dashboard form > .button {
  outline: 0;
  margin-top: 1%;
  margin-left: 0;
  background: none;
  background-color: rgba(38, 50, 56, 0.8);
  float: left;
  height: 52px;
  width: 30%;
  text-align: center;
  line-height: 42px;
  border: 0;
  color: #FFF;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 16px;
  text-rendering: auto;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  -webkit-transition: background-color .4s ease;
  transition: background-color .4s ease;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
}

.dashboard form > .button:hover {
  background-color: rgba(0, 150, 136, 0.8);
}

.dashboard .popular {
  border-top: 1px solid #282c34;
  width: 96%;
  align-self: center;
  display: flex;
  flex-direction: column;
  flex-flow: wrap;
}

.dashboard h3 {
  width: 100%;
  align-self: center;
  font-size: 14pt;
  font-family:
    "SFMono-Regular",
    Consolas,
    "Liberation Mono",
    Menlo,
    Courier,
    monospace;
}

.dashboard h4 {
  width: 100%;
  align-self: center;
  font-size: 15pt;
  padding: 0;
  margin-top: 4px;
  font-family:
    "SFMono-Regular",
    Consolas,
    "Liberation Mono",
    Menlo,
    Courier,
    monospace;
}

.dashboard .book-cards {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  height: 270px;
}

.dashboard .add-button {
  outline: 0;
  background: none;
  background-color: rgba(38, 50, 56, 0.8);
  float: left;
  height: 52px;
  width: 100%;
  text-align: center;
  border: 0;
  margin: 4px;
  margin-bottom: 10px;
  color: #FFF;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 16px;
  text-rendering: auto;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  -webkit-transition: background-color .4s ease;
  transition: background-color .4s ease;
  border-radius: 6px;
  cursor: pointer;
  font-family:
    "SFMono-Regular",
    Consolas,
    "Liberation Mono",
    Menlo,
    Courier,
    monospace;
}

.dashboard .book {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
}
@media screen and (max-width: 800px) {
  .dashboard {
    margin-top: 60%;
    height: 60%;
  }
  .dashboard form {
    width: 90%;
  }
  .dashboard .book-cards {
    flex-direction: row;
    width: 100%;
  }
}

.returned {
  display: flex;
  flex-direction: row;
  margin-top: 0;
}

.search {
  display: flex;
  flex-direction: column;
}

.topbooks {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  width: 95%;
  background: teal;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#35394a', endColorstr='#1f222e',GradientType=1 ); 
  position: fixed;
  align-self: center;
  height: 80%;
  border-radius: 16px;
  opacity: 0.9;
  box-shadow: 0.5px 2px 7px #282c34;
  padding-top: 1px;
  padding-left: 20px;
  margin-bottom: 2px;
}

.topbooks h3 {
  width: 100%;
  align-self: center;
  font-size: 10pt;
  font-family:
    "SFMono-Regular",
    Consolas,
    "Liberation Mono",
    Menlo,
    Courier,
    monospace;
}

.topbooks h4 {
  margin-top: 9px;
  width: 100%;
  align-self: center;
  font-size: 16pt;
  font-family:
    "SFMono-Regular",
    Consolas,
    "Liberation Mono",
    Menlo,
    Courier,
    monospace;
}

.topbooks .cards {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  width: 100%;
  height: 70%;
  margin-top: 0;
}

.topbooks .add-button {
  outline: 0;
  background: none;
  background-color: rgba(38, 50, 56, 0.8);
  float: left;
  height: 42px;
  width: 100%;
  text-align: center;
  border: 0;
  margin: 0;
  color: #FFF;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 9pt;
  text-rendering: auto;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  -webkit-transition: background-color .4s ease;
  transition: background-color .4s ease;
  border-radius: 6px;
  cursor: pointer;
  font-family:
    "SFMono-Regular",
    Consolas,
    "Liberation Mono",
    Menlo,
    Courier,
    monospace;
}


@media screen and (max-width: 800px) {
  .topbooks {
    margin-top: 20%;
    height: 75%;
  }
}
