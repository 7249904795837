/* * {
  box-sizing: border-box;
} */

html {
  font-size: 62.5%;
}

html, body {
  font-family: 'Roboto', sans-serif;
}

h1, h2, h3, h4, h5 {
  font-family: 'Indie Flower', cursive;
}

h1 {
  font-size: 4rem;
}

h2 {
  font-size: 3.2rem;
  padding-bottom: 10px;
}

h4 {
  font-size: 2.5rem;
  padding-bottom: 10px;
}

p {
  line-height: 1.5;
  font-size: 1.6rem;
  padding-bottom: 10px;
}

img {
  max-width: 100%;
  height: auto;
}

.container {
  width: 100%;
  margin: auto;
}

.App {
  display: flex;
  flex-direction: column;

}

/* Material UI Updates */

.MuiTypography-body2{
  font-size: 1.3rem !important;
}

.MuiTypography-body1{
  font-size: 1.4rem !important;
}

.MuiTypography-h5{
  font-size: 1.7rem !important;
}

.MuiIconButton-root .MuiSvgIcon-root{
  font-size: 2.4rem !important;
  color: teal !important;
}

.MuiAvatar-colorDefault{
  background-color: teal !important;
}

*{
  box-sizing: border-box;
}